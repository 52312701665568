const CreateFilters = (Vue) => {

    Vue.filter('titleCase', function (value) {
        if (value) {
            return (value)
                .split(' ')
                .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                .join(' ');
        }
    });

}

export default CreateFilters