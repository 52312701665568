<template>
  <b-container flex="sm" class="py-5 sign-up">
    <b-card>
      <h3 class="text-center">Sign Up</h3>
      <hr />
      <OAuthComponent />
      <hr />
      <div v-if="!isProduction">
        <b-form @submit.prevent="signup">
          <b-form-group label="Name">
            <b-form-input v-model="displayName" trim></b-form-input>
          </b-form-group>
          <b-form-group label="Email">
            <b-form-input
              v-model="email"
              type="email"
              trim
              :formatter="(v) => v.toLowerCase()"
            />
          </b-form-group>
          <b-form-group label="Password">
            <b-form-input type="password" v-model="password"></b-form-input>
          </b-form-group>
          <b-form-group label="Confirm Password">
            <b-form-input type="password" v-model="confirm_password"></b-form-input>
          </b-form-group>
          <div class="text-center">
            <b-button variant="light" type="submit" :disabled="formInvalid">
              Sign Up
            </b-button>
          </div>
        </b-form>
        <hr />
      </div>
      <div class="text-center text-muted">
        <small>
          By signing up you indicate that you have read and agree to our
          <b-link to="/legal/terms" target="_blank">terms &amp; conditions of use</b-link>
          and <b-link to="/legal/privacy" target="_blank">privacy policy</b-link>.
        </small>
      </div>
      <div v-if="!isProduction">
        <hr />
        <p class="mb-0 text-center">
          <small>Already have an account? <b-link to="/login"> Sign In </b-link></small>
        </p>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import OAuthComponent from "@/components/OAuthComponent";

export default {
  name: "app-sign-up",

  metaInfo: {
    title: "Sign Up",
  },

  components: {
    OAuthComponent,
  },

  data: () => ({
    displayName: "",
    email: "",
    password: "",
    confirm_password: "",
  }),

  computed: {
    formInvalid() {
      if (this.displayName == "") return true;
      if (this.email == "") return true;
      if (this.password == "") return true;
      if (this.password != this.confirm_password) return true;
      if (this.password.length < 4) return true;
      return false;
    },
  },

  methods: {
    async signup() {
      const payload = {
        displayName: this.displayName,
        email: this.email,
        password: this.password,
      };
      try {
        await this.$store.dispatch("app/setLoading", true);
        await this.$store.dispatch("auth/signup", payload);
        await this.$store.dispatch("app/setLoading", false);
        this.$router.replace("/welcome");
      } catch (e) {
        this.errorToast(`Failed to sign in: ${e.message}`);
        await this.$store.dispatch("app/setLoading", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-up {
  display: flex;
  justify-content: center;

  .card {
    max-width: Min(400px, 90vw);
  }
}
</style>
